import React from "react"
import painHero from '../images/painHero.jpg';
import Layout from "../components/layout"
import Product from "../components/product/product"
import SEO from "../components/seo"
import ami from "../images/products/bread/amidonier.jpg";
import bag from "../images/products/bread/baguette.jpg";
import bagS from "../images/products/bread/baguetteSarrasin.jpg";
import buch from "../images/products/bread/bucheron.jpg";
import epe from "../images/products/bread/epeautre.jpg";
import rou from "../images/products/bread/rougeAlsace.jpg";
import gra from "../images/products/bread/graine.jpg";
import med from "../images/products/bread/medieval.jpg";

const products =[
  {
    name:"Pain Bûcheron",
    description : "Le pain bûcheron est composé de farine de seigle bio et de farine sans additifs.",
    img : buch
  },
  {
    name:"Amidonnier",
    description : "L'Amidonnier est composé d’une ancienne variété de blé et de farine sans additifs.",
    img : ami
  },
  {
    name:"Baguette",
    description : "La baguette est composée de farine de tradition sans additifs.",
    img : bag
  },
  {
    name:"Baguette sarrasin",
    description : "La baguette sarrasin est composée de farine sans additifs et de farine de sarrasin bio et de graines de pavot.",
    img : bagS
  },
  {
    name:"Pain à l'épeautre",
    description : "Le pain à l’épeautre est composé de farine d’épeautre et de farine sans additifs avec du levain maison.",
    img : epe
  },
  {
    name:"Rouge d'Alsace",
    description : "Le rouge d’Alsace est composé d’une ancienne variété de blé bio, de farine sans additifs ainsi que de levain maison.",
    img : rou
  },
  {
    name:"Médiéval",
    description : "Le médiéval est composé de 5 anciennes variétés de blé bio et de farine sans additifs.",
    img : med
  },
  {
    name:"Pain aux graines",
    description : "Le pain aux graines est composé de farine de tradition sans additifs et de graines.",
    img : gra
  },
]

const PainPage = () => (
  <Layout>
    <SEO title="Pain" />
    <div className="products">
      <section>
        <div className="fluid-container">
          <div className="row">
            <img src={painHero} alt="Pain" style={{width:"100%"}}/>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <h1 className="col-md-12 times" style={{textAlign:"center",margin:"62px 0px"}}>Nos pains</h1>
          </div>
          <div className="row">
            <div className="col-md-12" style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            {
              products.map((product)=><Product name={product.name} description={product.description} img={product.img} />)
            }
            </div>
          </div>
        </div>
      </section>

    </div>
  </Layout>
)

export default PainPage
